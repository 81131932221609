import React from 'react';
import {
  ButtonGroup,
  IconButton,
  Button,
  Link
} from '@chakra-ui/react';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin
} from 'react-icons/fa';

const SocialMediaButtons: React.FC<{
  variant?: "outline" | "ghost" | "unstyled" | "link" | "solid",
  variantColor?: string
}> = ({
  variant = "solid",
  variantColor = "gray" }) => {

    return (
      <ButtonGroup>
        <IconButton
          variant={variant}
          as="a"
          target="_blank"
          href="https://www.linkedin.com/company/mountaintop-advisors/"
          aria-label="linkedin"
          icon={<FaLinkedin />}
        />
        {/* <IconButton
          variant={variant}
          variantColor={variantColor}
          aria-label="facebook"
          icon={<FaFacebookF />} />
        <IconButton
          variant={variant}
          variantColor={variantColor}
          aria-label="instagram"
          icon={<FaInstagram />} />
        <IconButton
          variant={variant}
          variantColor={variantColor}
          aria-label="twitter"
          icon={<FaTwitter />} />
        <IconButton
          variant={variant}
          variantColor={variantColor}
          aria-label="youtube"
          icon={<FaYoutube />} /> */}
      </ButtonGroup>
    )
  }

// SocialMediaButtons.defaultProps = {
//   variant: "solid",
//   variantColor: "gray"
// }

export default SocialMediaButtons;