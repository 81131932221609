import React, { useState, createRef } from 'react';

import {
  Box,
  Button,
  FormControl,
  Input,
  Textarea,
  Flex,
  useToast,
  Text,
  Link
} from '@chakra-ui/react';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import { sendMailer } from '../../api';

// import axios from 'axios';

import ReCAPTCHA from 'react-google-recaptcha';
import { Mailer } from '../../common/types';

const ContactForm: React.FC = () => {
  const toast = useToast();
  // const [reCaptchaRef, setReCaptchaRef] = useState<ReCAPTCHA | null>();
  const recaptchaRef = createRef<ReCAPTCHA>();

  return (
    <Box
      w="100%"
      maxWidth={"xl"}>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          message: ''
        }}
        onSubmit={async (values, actions) => {
          // const config = {
          //   headers: {
          //     'Content-Type': 'application/json'
          //   }
          // }
          try {
            const token: string = await recaptchaRef.current?.executeAsync() || "";

            const payload: Mailer = { ...values, token };
            console.log(payload);

            // await axios.post('/mailer/send', payload, config);
            await sendMailer(payload);
            toast({
              title: "Information submitted.",
              description: "Expect to hear from us soon.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          } catch (error) {
            toast({
              title: "Something went wrong.",
              description: "Unable to send contact information.",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
          actions.setSubmitting(true);
          actions.resetForm();
          recaptchaRef.current?.reset();
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Flex
              w="100%"
              justify="space-between"
              direction={['column', 'column', 'column', 'row']}
              mb={4}
            >
              <FormControl
                flexBasis={0}
                flexGrow={1}
                mr={[0, 0, 0, 4]}
                mb={[4, 4, 4, 0]}
                isRequired
              >
                <Input
                  backgroundColor="white"
                  size="lg"
                  onChange={handleChange}
                  value={values.name}
                  id="name"
                  placeholder="Name"
                />
              </FormControl>
              <FormControl
                flexBasis={0}
                flexGrow={1}
                isRequired
              >
                <Input
                  backgroundColor="white"
                  size="lg"
                  onChange={handleChange}
                  value={values.email}
                  type="email"
                  id="email"
                  placeholder="Email"
                />
              </FormControl>
            </Flex>

            <FormControl mb={4} isRequired>
              <Input
                backgroundColor="white"
                size="lg"
                onChange={handleChange}
                value={values.phone}
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                id="phone"
                placeholder="Phone"
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <Textarea
                backgroundColor="white"
                size="lg"
                onChange={handleChange}
                value={values.message}
                id="message"
                placeholder="Message"
              />
            </FormControl>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6Ldd1LUZAAAAAI7w24yH7y7rhwlyTeX_HdBLmDQL"
              badge="bottomright"
            />
            <Text fontSize="sm" color="gray.500">
              This site is protected by reCAPTCHA and the Google{" "}
              <Link color="teal.500" href="https://policies.google.com/privacy">
                Privacy Policy
              </Link>
              {" "}and {" "}
              <Link color="teal.500" href="https://policies.google.com/terms">Terms of Service</Link>
              {" "}apply.
            </Text>
            <Button
              w={['full', 'full', 'full', 'auto']}
              isLoading={isSubmitting}
              type="submit"
              colorScheme="blue"
              mt={4}>
              Submit
            </Button>

          </form>
        )}
      </Formik>
    </Box >
  )
}

export default ContactForm;