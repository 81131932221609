import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCp_P7A_vlx-KKvAJmu64xRIrZCFPBGRao",
    authDomain: "mountaintop-advisors.firebaseapp.com",
    projectId: "mountaintop-advisors",
    storageBucket: "mountaintop-advisors.appspot.com",
    messagingSenderId: "1053615318186",
    appId: "1:1053615318186:web:076fe00ef7cd4216cd5cd3",
    measurementId: "G-WERWQN4C2H"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//   firebase.functions().useEmulator("localhost", 5001)
const fn = firebase.functions();
const db = firebase.firestore();
const analytics = firebase.analytics();
const storage = firebase.storage();

if (window.location.hostname === "localhost") {
    console.log('using firestore emulator');
    db.useEmulator("localhost", 8080);
    fn.useEmulator("localhost", 5001);
} else {
    db.settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
    db.enablePersistence();
}

// db.settings({
//     cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
// });
// db.enablePersistence();

export {
    firebase, fn, db, analytics, storage
}