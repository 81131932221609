import {
  FaRegHandshake,
  FaLaptopCode
} from 'react-icons/fa';
import {
  RiBankLine,
  RiLightbulbLine,
  RiVideoChatLine,
  RiChatSettingsLine,
  RiCalendarTodoLine
} from 'react-icons/ri';
import {
  BsGraphUp
} from 'react-icons/bs';
import {
  BsPencilSquare
} from 'react-icons/bs';

const services = [
  {
    name: 'Campaign Strategy',
    description: '',
    icon: RiLightbulbLine
  },
  {
    name: 'Government Relations',
    description: '',
    icon: RiBankLine
  },
  {
    name: 'Public Relations',
    description: '',
    icon: FaRegHandshake
  },
  {
    name: 'Business Development',
    description: '',
    icon: BsGraphUp
  },
  {
    name: 'Media Planning',
    description: '',
    icon: RiCalendarTodoLine
  },
  {
    name: 'Social Media Management',
    description: '',
    icon: RiChatSettingsLine
  },
  {
    name: 'Website Development',
    description: '',
    icon: FaLaptopCode
  },
  {
    name: 'Video Production',
    description: '',
    icon: RiVideoChatLine
  },
  {
    name: 'Graphic Design',
    description: '',
    icon: BsPencilSquare
  }
];

export default services;