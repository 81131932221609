import React from 'react';
import {
    Box,
    Heading, Image, ListItem, Text, UnorderedList, VStack
} from '@chakra-ui/react';
import {Helmet} from 'react-helmet';

import Container from '../../components/Container';
import Section from '../../components/Section';

const About: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Anton Castaneda: Political Strategy Leader at Mountaintop Advisors</title>
                <meta name="description" content="Explore the professional world of Anton Castaneda at Mountaintop Advisors, where political acumen meets innovative strategy. Gain insights into his expertise in political consultancy and how he's shaping the future of political campaigns." />
            </Helmet>
            <Section backgroundColor="white" color="black" py={16}> 
                <Container maxW="620">
                    <VStack spacing={6} align="stretch">
                        <Heading as="h1" size="2xl" textAlign="center">
                            Anton Castaneda: Our Political Strategy Expert
                        </Heading>
                        <Text>
                            Welcome to Mountaintop Advisors, where strategic foresight meets transformative action. At the helm of our firm is Anton Castaneda, a distinguished figure in political strategy and public policy advocacy. Our commitment at Mountaintop Advisors is to forge the future of political engagement and policy shaping.
                        </Text>
                        <Heading as="h2" size="xl" textAlign="center">
                            Our Founder: Anton Castaneda
                        </Heading>
                        <Box>
                            <Image src={process.env.PUBLIC_URL + '/anton_castaneda.jpg'} />
                        </Box>
                        <Text>
                            Anton Castaneda is a beacon in the realms of political strategy and policy advocacy. His educational journey, marked by a Bachelor of Arts in Political Science, Magna Cum Laude, from the University of California, Irvine, and a Master's in Political Management from George Washington University, demonstrates a perfect union of academic excellence and practical application.
                        </Text>
                        <Heading as="h3" size="lg" textAlign="center">
                            Anton Castaneda's Key Strengths: Pioneering Excellence in Political Strategy and Advocacy
                        </Heading>
                        <UnorderedList paddingLeft="1em">
                            <ListItem>
                                Strategic Luminary: Anton Castaneda is renowned as a strategic luminary, adept at charting innovative paths through complex political terrains.
                            </ListItem>
                            <ListItem>
                                Political Virtuoso: With a wealth of experience in the political sphere, Anton Castaneda boasts an unparalleled understanding of the intricacies of politics.
                            </ListItem>
                            <ListItem>
                                Architect of Change: He excels at architecting influential policies that drive tangible societal change through adept policy advocacy and development.
                            </ListItem>
                            <ListItem>
                                Campaign Orchestrator: Anton Castaneda's remarkable track record in overseeing successful political campaigns underscores his mastery of campaign management.
                            </ListItem>
                            <ListItem>
                                Government Maestro: Leveraging his extensive network and astute insights, he engages effectively with government bodies, fostering fruitful partnerships.
                            </ListItem>
                            <ListItem>
                                Educational Eminence: His academic prowess, highlighted by a Magna Cum Laude Bachelor of Arts in Political Science and a Master's in Political Management, reflects his dedication to intellectual rigor.
                            </ListItem>
                            <ListItem>
                                Paragon of Integrity: Anton Castaneda is recognized for his unimpeachable integrity, earning the trust and esteem of his peers in political circles.
                            </ListItem>
                            <ListItem>
                                Trailblazing Thinker: He consistently introduces pioneering ideas and strategies, catalyzing transformative change within the political and policy realms.
                            </ListItem>
                            <ListItem>
                                Inspirational Trailblazer: Anton Castaneda leads by example, inspiring his team to attain excellence in every endeavor.
                            </ListItem>
                            <ListItem>
                                Collaborative Maven: His collaborative disposition ensures that all stakeholders actively contribute to the realization of shared objectives.
                            </ListItem>
                            <ListItem>
                                Sector Polymath: Drawing from multifaceted sector experiences, he offers well-rounded counsel spanning the private, governmental, and nonprofit sectors.
                            </ListItem>
                            <ListItem>
                                Client-Centric Dedication: Anton Castaneda's client-centric focus transforms client goals into shared objectives.
                            </ListItem>
                            <ListItem>
                                Positive Change Agent: He believes in creating a positive impact that extends beyond individual client achievements, contributing to a better world.
                            </ListItem>
                            <ListItem>
                                Committed Partner: From the initial consultation to the fulfillment of objectives, Anton Castaneda stands as a dedicated partner, committed to guiding clients toward the zenith of their aspirations.
                            </ListItem>
                            <ListItem>
                                Inspired by Martin Luther King Jr.: His mission transcends traditional consultancy, echoing the inspiration drawn from Martin Luther King Jr.'s Mountaintop speech.
                            </ListItem>
                            <ListItem>
                                Holistic Visionary: Anton Castaneda employs a holistic approach, addressing immediate challenges while building the framework for sustained success.
                            </ListItem>
                        </UnorderedList>
                        <Text>
                            Anton Castaneda's key strengths serve as a guiding light for those navigating the intricate landscape of political strategy and policy advocacy.
                        </Text>
                        <Heading as="h3" size="lg" textAlign="center">
                            Our Mission
                        </Heading>
                        <Text>
                            Guided by Anton Castaneda's visionary leadership, Mountaintop Advisors is dedicated to providing top-tier expertise in political strategy, government relations, and public affairs. We navigate the intricate world of politics and policy, equipping our clients with strategies that make a tangible difference.
                        </Text>
                        <Heading as="h3" size="lg" textAlign="center">
                            Our Expertise
                        </Heading>
                        <Text>
                            Anton Castaneda's experience in key roles, including as Executive Director for both Opportunity Matters Network Inc. and Opportunity Matters Fund Inc., has given him a profound understanding of political dynamics. His expertise in creating and managing significant political action committees and non-profits, and his proven success in supporting political campaigns and policy initiatives, distinguishes Mountaintop Advisors in the field of political consultancy.
                        </Text>
                        <UnorderedList paddingLeft="1em">
                            <ListItem>
                                Political Strategy: Anton Castaneda's expertise in political strategy is a cornerstone of our consultancy, guiding clients through the intricacies of political landscapes.
                            </ListItem>
                            <ListItem>
                                Campaign Management: We excel in directing and managing successful political campaigns, ensuring our clients navigate elections with precision.
                            </ListItem>
                            <ListItem>
                                Government Relations: Leveraging Anton Castaneda's extensive network, we facilitate productive engagements with government entities, fostering collaboration.
                            </ListItem>
                            <ListItem>
                                Public Affairs: Our proficiency in public affairs equips clients with the tools to effectively communicate and engage with the public.
                            </ListItem>
                            <ListItem>
                                Policy Development: We specialize in formulating impactful policies that drive positive societal change, underpinned by Anton Castaneda's insight.
                            </ListItem>
                            <ListItem>
                                Advocacy: Anton Castaneda's dedication to advocacy means we champion causes aligned with our clients' values, amplifying their impact.
                            </ListItem>
                            <ListItem>
                                Nonprofit Support: We offer comprehensive support for nonprofits, drawing from Anton Castaneda's experience in managing political action committees and non-profit organizations.
                            </ListItem>
                            <ListItem>
                                Collaborative Approach: Our consultancy actively engages with clients, fostering collaboration to shape their long-term success.
                            </ListItem>
                            <ListItem>
                                Client-Centric Focus: We prioritize client success, turning their goals into shared objectives and guiding them to realization.
                            </ListItem>
                            <ListItem>
                                Educational Excellence: Anton Castaneda's academic achievements ensure our strategies are rooted in intellectual rigor.
                            </ListItem>
                            <ListItem>
                                Innovation: We continually introduce innovative ideas and strategies to catalyze transformative change.
                            </ListItem>
                            <ListItem>
                                Inspiration: Anton Castaneda inspires our team to attain excellence in every endeavor, setting a high standard for achievement.
                            </ListItem>
                            <ListItem>
                                Integrity: We uphold the highest standards of integrity, earning the trust and respect of peers in the political arena.
                            </ListItem>
                            <ListItem>
                                Sector Expertise: Drawing from diverse sector experiences, we offer well-rounded counsel spanning private, governmental, and nonprofit sectors.
                            </ListItem>
                            <ListItem>
                                Client Dedication: Our client-centric focus ensures we remain dedicated partners in achieving shared goals.
                            </ListItem>
                            <ListItem>
                                Positive Impact: We believe in making a positive impact that extends beyond individual client successes, contributing to a better world.
                            </ListItem>
                            <ListItem>
                                Holistic Approach: Our holistic vision addresses immediate challenges while building the foundation for sustained success.
                            </ListItem>
                            <ListItem>
                                Martin Luther King Jr. Inspiration: Our mission is inspired by Martin Luther King Jr.'s Mountaintop speech, transcending traditional consultancy.
                            </ListItem>
                            <ListItem>
                                Strategic Insight: Anton Castaneda's strategic insight is the driving force behind our success.
                            </ListItem>
                            <ListItem>
                                Comprehensive Perspective: We provide clients with a comprehensive view of their initiatives, from initial consultation to realization.
                            </ListItem>
                            <ListItem>
                                Collaboration: Collaboration is at the core of our approach, ensuring all stakeholders actively contribute to success.
                            </ListItem>
                            <ListItem>
                                Trailblazing: We lead with pioneering ideas and strategies, catalyzing transformative change.
                            </ListItem>
                            <ListItem>
                                Dedicated Partnership: We stand as dedicated partners from inception to achievement, guiding clients toward their aspirations.
                            </ListItem>
                        </UnorderedList>
                        <Text>
                            Anton Castaneda's Company combines these expertise areas to provide clients with unparalleled support and guidance, propelling them to success in the dynamic world of politics and policy advocacy.
                        </Text>
                        <Heading as="h3" size="lg" textAlign="center">
                            Our Services
                        </Heading>
                        <Text>
                            Mountaintop Advisors specializes in:
                        </Text>
                        <UnorderedList paddingLeft="1em">
                            <ListItem>
                                Political Strategy and Campaign Management: Harnessing Anton Castaneda's extensive experience in directing successful political campaigns and initiatives.
                            </ListItem>
                            <ListItem>
                                Government and Public Relations: Utilizing Anton Castaneda's broad network and insights to effectively engage with government entities and the public.
                            </ListItem>
                            <ListItem>
                                Policy Development and Advocacy: Employing Anton Castaneda's skill in formulating and championing policies that drive positive societal change.
                            </ListItem>
                        </UnorderedList>
                        <Heading as="h3" size="lg" textAlign="center">
                            Our Commitment
                        </Heading>
                        <Text>
                            Driven by Anton Castaneda's ethos of integrity, innovation, and impact, Mountaintop Advisors pledges to set new benchmarks in political consultancy. We are passionate about the role of informed strategy and committed advocacy in crafting a more positive political and social environment.
                        </Text>
                        <Heading as="h3" size="lg" textAlign="center">
                            Connect with Us
                        </Heading>
                        <Text>
                            Embark on a journey with Mountaintop Advisors to redefine the landscape of politics and policy. For insights, collaborations, or consultations, contact Anton Castaneda and our expert team. Together, we can reach the Mountaintop.
                        </Text>
                    </VStack>
                </Container>
            </Section>
        </>
    )
}

export default About;