import { fn, storage } from "../firebase";
import { Mailer } from "../common/types";

export const sendMailer = async (mailer: Mailer) => {
    // try {
    //     await db.collection('mailer').doc().set(mailer);
    // } catch (error) {
    //     console.error(error);
    //     throw error;
    // }

    const addMailer = fn.httpsCallable('addMailer');
    try {
        const result = await addMailer(mailer);
        console.log(result);
    } catch (error) {
        throw error;
    }

}

export const getImageUrls = async (path: string, pageToken: string | null = null) => {
    const suffix = '_300x300';
    const page = await storage.ref('media/images/thumbnails').list({
        maxResults: 100,
        pageToken
    });

    const downloadUrls = page.items.map(async itemRef => {
        return await itemRef.getDownloadURL() as string;
    });

    const urls = await Promise.all(downloadUrls);

    return {
        urls,
        nextPageToken: page.nextPageToken
    }
}