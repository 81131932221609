import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import {
  ChakraProvider as ThemeProvider,
  CSSReset,
  Box,
  Flex
} from '@chakra-ui/react';

import theme from "@chakra-ui/theme"

import './firebase';
import Footer from './components/Footer';
import Home from './pages/home/Home';
import About from './pages/about';
import Media from './pages/media';
import Navbar from './components/navbar';
import { Helmet } from 'react-helmet';



const App = () => {
  // const lgHeaderHeight = [20, 20, 20, 32];
  // const smHeaderHeight = [20, 20, 20, 20];
  // const [headerOffset, setHeaderOffset] = useState(lgHeaderHeight);
  // const headerOffset = smHeaderHeight
  // const location = useLocation();

  // useEffect(() => {
  //   window.onscroll = () => {
  //     if (window.pageYOffset === 0) {
  //       setHeaderOffset(lgHeaderHeight);
  //     } else {
  //       setHeaderOffset(smHeaderHeight);
  //     }
  //   }
  // }, [location])

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Helmet>
        <meta name="description" content="With experience in the private, governmental, and nonprofit sectors, Mountaintop Advisors is fully committed and equipped to advance your cause. We believe in the power of the work we do to not only advance our client’s cause, but create a more equitable and just world." />
        <title>Mountaintop Advisors - Political Consulting</title>
      </Helmet>
      <Flex
        minH="100vh"
        // justify="space-between"
        direction="column">
        {/* <Header height={headerOffset} /> */}
        <Navbar />
        {/* <Box
          mt={headerOffset}
        // mb="4rem"
        >

        </Box> */}
        <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/media">
              <Media />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            {/* 
            <Route path="/team">
              <Team />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route> */}
          </Switch>
        <Footer />
      </Flex>
    </ThemeProvider>
  );
}

export default App;
