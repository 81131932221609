import React, { useEffect, useRef, useState } from 'react';
import {
    AspectRatio,
    Box,
    SimpleGrid,
    Image,
} from '@chakra-ui/react';
import { getImageUrls } from '../../api';
import Container from '../../components/Container';



const Media: React.FC = () => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const nextPageTokenRef = useRef<string | null>(null);

    useEffect(() => {
        const onScroll = (e: any) => {
            console.log('onscroll');
            const bottom = window.innerHeight + Math.ceil(window.scrollY) >= document.body.offsetHeight;
            console.log(window.innerHeight, window.scrollY, document.body.offsetHeight)
            if (bottom) {
                console.log('scrolled to bottom');
                if (nextPageTokenRef.current) {
                    getImageUrls('media/images/thumbnails', nextPageTokenRef.current).then(({ urls, nextPageToken }) => {
                        setImageUrls(urls);
                        nextPageTokenRef.current = nextPageToken;
                    });
                }
            }
        }

        getImageUrls('media/images/thumbnails').then(({ urls, nextPageToken }) => {
            setImageUrls(urls);
            nextPageTokenRef.current = nextPageToken;
        });



        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const handleImageClick = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    }

    return (
        <Container>
            <Box>
                <SimpleGrid minChildWidth="150px" gap={4}>
                    {imageUrls.map(url => (
                        <AspectRatio
                            onClick={() => handleImageClick(url)}
                            key={url}
                            maxW="200px"
                            ratio={1}
                        >
                            <Box
                                borderWidth="1px"
                                borderRadius="lg"
                                _hover={{ cursor: "pointer" }}
                                shadow="lg" >
                                <Image
                                    w="full"
                                    h="full"
                                    objectFit="cover"
                                    src={url}
                                />
                            </Box>
                        </AspectRatio>
                    ))}
                </SimpleGrid>
            </Box>
        </Container>
    );
}

export default Media;