import React from 'react';
import {
  Box, FlexProps
} from '@chakra-ui/react';

const Container: React.FC<FlexProps> = props => {

  return (
    <Box
      maxW={1200}
      mx="auto"
      w="100%"
      px={[4, 4, 8, 4]}
      {...props}
    >
      {props.children}
    </Box>
  )
}

export default Container;