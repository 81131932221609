import React, { useRef } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Link,
  Stack,
  Image,
  Divider
} from '@chakra-ui/react';
import {
  FaPhone,
  FaEnvelope
} from 'react-icons/fa';
/*
// @ts-ignore */
import Fade from 'react-reveal/Fade';
import Section from '../../components/Section';
import Container from '../../components/Container';
import ContactForm from '../../pages/contact/ContactForm';
import Card from '../../components/Card';
import Services from './Services';
import SocialMediaButtons from '../../components/SocialMediaButtons';
import clients from './Clients';
import Carousel from '../../components/Carousel';

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)

const Home: React.FC = () => {
  const contactRef = useRef(null);
  const executeScroll = () => scrollToRef(contactRef);

  return (
    <Box>
      <Section
        color="white"
        backgroundColor="cyan.700"
        py={[12, 16, 32, 64]}
      >
        <Container>
          <Heading
            fontSize={['5xl', '6xl']}
            mb={8}
          >Mountaintop Advisors</Heading>
          <Text
            mb={6}
            fontSize="xl"
          >Because the Mountaintop has the best views. Let's get there together!</Text>
          <Button
            colorScheme="purple"
            size="lg"
            w={['100%', 'auto']}
            onClick={executeScroll}
          >Contact Us</Button>
        </Container>
      </Section>
      {/* <Fade bottom> */}
      <Section
        color="black"
        backgroundColor="black"
        backgroundPosition="center"
        backgroundSize={['auto 100%', 'cover', 'cover', '100%']}
        backgroundImage={'url(' + process.env.PUBLIC_URL + '/mountain.jpg)'}
        py={16}
      >
        <Container>
          <Flex
            p={4}
            justify="space-between"
            direction={["column", "column", "row"]}
          >
            <Box
              flexBasis={0}
              flexGrow={1}
              color="white"
            >
              <Text fontSize="4xl" mb={2}>About Us</Text>
              {/* <Box color="black" borderWidth={1} /> */}
              <Text fontSize="lg">
                Mountaintop Advisors takes its name from Martin Luther King Jr.’s iconic Mountaintop speech. When you are a Mountaintop client, Mountaintop’s mission is to join you on your journey and ensure you reach the summit of your goals. With experience in the private, governmental, and nonprofit sectors, Mountaintop Advisors is fully committed and equipped to advance your cause. We believe in the power of the work we do to not only advance our client’s cause, but create a more equitable and just world.
              </Text>
            </Box>
            <Box
              flexBasis={0}
              flexGrow={1}
            >
            </Box>
          </Flex>
        </Container>
      </Section>
      {/* </Fade>
      <Fade bottom> */}
      <Section
        color="black"
        backgroundColor="gray.50"
        py={16}
      >
        <Container>
          <Flex mb={16} justify="center">
            <Text fontSize="4xl" color="gray.800">What We Do</Text>
            {/* <Heading as="h1" size="2xl" color="blue.900">What We Do</Heading> */}
            {/* <Text fontSize="4xl" mb={4}>What We Do</Text> */}
          </Flex>
          <Flex
            justify="center"
            flexWrap="wrap"
          >
            {Services.map((service, index) => {
              return (
                <Box
                  key={index}
                  color="white"
                  backgroundColor="gray.800"
                  m={[2]}
                  p={12}
                  w={['100%', '50%', '30%']}
                  // shadow="lg" 
                  borderWidth={1}>
                  <Card {...service}></Card>
                </Box>
              )
            })
            }
          </Flex>
        </Container>
      </Section>
      {/* <Section
        color="white"
        backgroundColor="purple.900"
        py={10}
      >
        <Container>
          <Flex justify="center">
            <Text fontSize="4xl">Our Clients</Text>
          </Flex>
          <Flex
            justify="center"
            flexWrap="wrap"
          >
            <Carousel items={clients.map((client) => {
              return (
                <Link href={client.url}
                  isExternal>
                  <Image
                    h="100%"
                    w="100%"
                    alt={client.alt}
                    src={client.logo}
                  />
                </Link>
              )
            })
            } />

          </Flex>
        </Container>
      </Section> */}
      {/* </Fade> */}
      {/* <Fade bottom>
        <Section
          color="white"
          backgroundColor="blue.900"
          py={64}
        >
          <Container>
            <Flex
              p={4}
              mt={4}
              justify="space-between"
            >
              <Box
                flexBasis={0}
                flexGrow={1}
              >
                <Text fontSize="3xl">
                  Content Header
              </Text>
              </Box>
              <Box
                flexBasis={0}
                flexGrow={1}
              >
                content
            </Box>
            </Flex>
          </Container>
        </Section>
      </Fade> */}
      {/* <Fade bottom> */}
      <Box
        ref={contactRef}>
        <Section
          color="black"
          backgroundColor="cyan.700"
          py={[8, 16]}
        >
          <Container>
            <Flex
              p={4}
              justify="space-between"
              direction={["column", "column", "row"]}
            >
              <Box
                flexBasis={0}
                flexGrow={1}
                color="white"
                mr={[0, 0, 0, 10]}
                mb={[8, 8, 8, 0]}
              >
                <Text fontSize="4xl" mb={2}>Contact Us</Text>
                <Text fontSize="lg">Feel free to contact us any time. We look forward to working with you.</Text>
                <Divider mt={4} />
                <Stack mt={4} alignSelf="flex-end">
                  <Text fontSize="2xl">Address</Text>
                  <Text fontSize="lg" mb={2}>1100 New Jersey Ave SE<br />Suite 2163<br />Washington D.C. 20003</Text>
                  <Text fontSize="2xl" mb={2}>Other ways to connect</Text>
                  <Stack isInline align="center">
                    <Box as={FaPhone} />
                    <Link href="tel:+1-202-940-5518">(202)-940-5518</Link>
                  </Stack>
                  <Stack isInline align="center">
                    <Box as={FaEnvelope} />
                    <Link href="mailto:info@mountaintopadvisors.com">info@mountaintopadvisors.com</Link>
                  </Stack>
                  <Text fontSize="2xl" my={2}>Follow Us</Text>
                  <SocialMediaButtons variant="outline" />
                </Stack>
              </Box>
              <Box
                flexBasis={0}
                flexGrow={1}
                backgroundColor="gray.200"
                p={8}
              >
                <ContactForm />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Box>
      {/* </Fade> */}
    </Box>
  );
}

export default Home;