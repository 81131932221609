import React from "react";

import { Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Logo = ({height = "100%"}) => {
    return (
        <Link to="/" style={{height}}>
            <Image 
                h="full"
                src={process.env.PUBLIC_URL + '/logo_text_white.svg'}
                alt="Mountaintop Advisors Logo" />
         </Link>
    )
}

export default Logo;