import React from "react";
import { Container, HStack } from "@chakra-ui/react"
import { useLocation } from "react-router";
import { NavItem } from "."
import Logo from "../Logo";
import NavLink from "../NavLink";

interface DesktopNavbarProps {
    navItems: NavItem[]
}

const DesktopNavbar = (props: DesktopNavbarProps) => {
    const {
        navItems
    } = props

    const location = useLocation()
    return (
        <Container py={0} maxW="1200px">
            <HStack
                h="80px"
                py="15px"
                justifyContent='space-between'>
                <Logo />

                <HStack spacing={4}>
                    {navItems.map(item => <NavLink
                        key={item.label}
                        isActive={item.route === location.pathname}
                        to={item.route}>{item.label}</NavLink>)}
                </HStack>

            </HStack>
        </Container>
    )
}

export default DesktopNavbar;