import React from 'react';
import { Stack, Text, Icon } from '@chakra-ui/react';

import { IconType } from 'react-icons/lib';

const Card: React.FC<{ name: string, description: string, icon: IconType }> = props => {
  const {
    name,
    description,
    icon
  } = props
  return (
    <Stack
      align="center"
      spacing={6}
    >
      <Icon
        as={icon}
        background="cyan.700"
        padding={5}
        borderWidth={2}
        boxSize="50%" />

      <Text textAlign="center" fontSize="2xl" fontWeight="medium">{name.toUpperCase()}</Text>
      {description && <Text>{description}</Text>}
    </Stack>
  )
}

export default Card;