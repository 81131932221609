import React from 'react';
import {
  Flex,
  FlexProps
} from '@chakra-ui/react';

const Section: React.FC<FlexProps> = props => {

  return (
    <Flex
      bottom={0}
      left={0}
      right={0}
      top={0}
      py={32}
      color="white"
      backgroundColor="cyan.900"
      justify="center"
      {...props}
    >
      {props.children}
    </Flex>
  )
}

export default Section;