import React from 'react';
import {
  Flex,
  Text
} from '@chakra-ui/react';
import Container from './Container';

const Footer: React.FC = () => {
  return (
    <Flex
      bottom={0}
      left={0}
      right={0}
      color="white"
      bg="gray.700"
      direction="column"
      py={4}
      marginTop='auto'
    >
      <Container>
        <Flex
          justify="space-between"
          align="center" >
          <Text fontSize="xs">© 2020 Mountaintop Advisors LLC. All Rights Reserved</Text>
        </Flex>
      </Container>

    </Flex>
  )
}

export default Footer;